@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-toastr/toastr";

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

.ng-select-container {
  background-color: var(--kt-input-solid-bg) !important;
  border-color: var(--kt-input-solid-bg) !important;
  color: var(--kt-input-solid-color) !important;
  transition: color 0.2s ease !important;
  padding: 13px 0px !important;
  min-height: 43px !important;
}
.ng-input,
.ng-placeholder {
  margin-top: 6px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}
